import { Paper, Typography, Divider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './logo.css'

export function Instructions () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='logo-container'>
        <div className='logo'></div>
      </div>
    <Paper elevation={0} sx={{ width: '90%', m: 'auto', mt: '20px'}}>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe 
          src="https://www.youtube.com/embed/hg2W-1GcVvs?si=bKPX_0bnss27dJbV" 
          title="YouTube video player" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" 
          allowFullScreen
          style={{ 
        width: '100%', 
        maxWidth: '800px', 
        aspectRatio: '16/9' 
          }}
        ></iframe>
      </div>

      <Typography variant="body1" gutterBottom>
      <Divider />
        GENERAL QUESTIONS
        <Divider />

<p>1. What is the Twelfth Monkey Virtual Relay Race?</p>

<p>The Twelfth Monkey is a virtual relay event where teams compete to complete a set distance as quickly as possible, passing a virtual 'baton' from one team member to the next.</p>

<p>2. How do teams participate?</p>

<p>Teams participate by running or walking their leg of the race and using the Strava app to track their distance and time. The Twelfth Monkey system automatically handles baton passing between team members.</p>

<p>3. What equipment is needed?</p>

<p>Participants need a smartphone with the Strava app installed to track their activity.</p>

<p>4. How do I register my team?</p>

<p>A team manager must sign up on the Twelfth Monkey website, create a team, and invite members by entering their mobile phone numbers.</p>

<Divider />
REGISTRATION PROCESS
<Divider />

<p>5. How does a team manager register?</p>

<p>The team manager signs up on www.twelfth-monkey.com with their email and phone number, receives a verification code, and upon verification, waits for admin approval to officially become a Team Manager.</p>

<p>6. How do team managers add members?</p>

<p>Team managers add members by entering their phone numbers in the correct format on the Twelfth Monkey website. Members are then invited to join the team via SMS.</p>

<p>7. How do team members join?</p>

<p>Team members receive an SMS invitation, click the link to connect their Strava account with the Twelfth Monkey system, and authorize the connection.</p>

<Divider />
ADDITIONAL INFORMATION
<Divider />
<p>8. How are teams ranked?</p>

<p>Teams are ranked based on the total time taken to complete the event distance.</p>

<p>9. Where can I find information about upcoming Twelfth Monkey events?</p>

<p>Upcoming events will be announced on social media and the Twelfth Monkey website.</p>

<p>10. Who can I contact for more information?</p>

<p>For further inquiries, contact your team manager. They will be provided with necessary contact information after registration. </p>
           </Typography>
    </Paper>
    </ThemeProvider>
  );
}

export default Instructions;