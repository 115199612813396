import { Amplify, Hub } from 'aws-amplify';

import { GraphQLQuery } from '@aws-amplify/api';

import { CssBaseline,  Paper } from '@mui/material';

import '@inovua/reactdatagrid-enterprise/index.css';
import '@aws-amplify/ui-react/styles.css';
import { listEvents, } from './graphql/queries';


import {  API, GRAPHQL_AUTH_MODE} from '@aws-amplify/api';
import { Event } from './API';
import { ListEventsQuery  } from './API';
import './logo.css';  

import { ThemeProvider} from '@mui/material/styles';
import theme from './theme';

import {
  defaultDarkModeOverride,
  ThemeProvider as AmplifyThemeProvider,
} from '@aws-amplify/ui-react';


import { useEffect, useState } from 'react';

import '@aws-amplify/ui-react/styles.css';

// import { useMediaQuery } from '@mui/material';


import awsconfig from './updatedAwsConfig';
import EventData from './EventData';

Amplify.configure(awsconfig);

const amplifyTheme = {
  name: 'my-theme',
  overrides: [defaultDarkModeOverride],
};

// modal using material ui that provides a way to confirm the deletion of a team member




export function Results() {


  // const isMobile = useMediaQuery('(max-width:600px)');

  const [events, setActiveEvents] = useState<Array<Event>>([]);

  useEffect(() => {
    // declare the data fetching function
    const fetchActiveEvents = async () => {
      // fetch the events from the database
      const eventsData = await API.graphql<GraphQLQuery<ListEventsQuery>>({
        query: listEvents,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      // remove all the events that are started and not archived
      const activeEvents = eventsData.data!.listEvents!.items!.filter((event) => event && event.started && !event.archived );

      // sort active events by createdAt, an ISO 8601 string UTC time with fractional seconds
      activeEvents.sort((a, b) => {
        if (a && b) {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }
        return 0;
      });

      // get the queryString data from the URL
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const eventId = urlParams.get('eventId');

      // if the URL has an eventId, set the active events to only the event with that ID
      if (eventId) {
        const event = activeEvents.find((event) => event?.id === eventId);
        if (event) {
          setActiveEvents([event] as Event[]);
          return;
        }
      }

      // set the active events
      setActiveEvents(activeEvents as Event[]);


    }

    fetchActiveEvents()
    // make sure to catch any error
    .catch(console.error);
  }, []);


  return (
    <AmplifyThemeProvider theme={amplifyTheme} colorMode="dark">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='logo-container'>
        <div className='logo'></div>
      </div>
    <Paper elevation={0} sx={{ width: '95%', m: 'auto', mt: '20px'}}>


      {events && events.length > 0 && events.map((event: any) => {
        return (
          <>
          <EventData id={event.id}  />
          </>
        );
      })}

    </Paper>
    </ThemeProvider>
    </AmplifyThemeProvider>
  );
}

Hub.listen('auth', (data) => { 
  if (data.payload.event === 'signIn_failure') {
      console.log(data);
  }
})

export default Results;